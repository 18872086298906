import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { useNavigate } from "react-router-dom";
// material
import {
  Stack,
  Typography,
  Card,
  CardHeader,
  CardContent,
  OutlinedInput,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// gql
import { useMutation } from "@apollo/client";
import { gqlError } from "../../../gql/gqlError";
import { ASK_QUESTION_QUERY } from "../../../gql/UserQueries";
// alerts
import { ShowAlert } from "../../Alert";

// question form
function QuestionForm() {
  const navigate = useNavigate();

  // update child mutation
  const [askQuestion, { error }] = useMutation(ASK_QUESTION_QUERY, {
    errorPolicy: "all",
    onError: gqlError,
  });

  if (error) {
    console.log("Getting AskQuestion Query Error: ", error);
  }

  const RegisterSchema = Yup.object().shape({
    question: Yup.string().required("Question required"),
  });

  const formik = useFormik({
    initialValues: {
      question: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      const askQuestionInput = {
        userId: localStorage.getItem("id"),
        question: values.question,
      };

      // send to server
      askQuestion({ variables: { askQuestionInput } })
        .then(({ data }) => {
          console.log(data);
          if (data) {
            console.log("Question sent successfully!");
            ShowAlert("Question sent successfully!", "success");
            window.location.href = "/app/question";
          }
          if (!data) {
            console.log("Question not sent!");
            ShowAlert("Question not sent!", "error");
            window.location.href = "/app/question";
          }
        })
        .catch((error) => {
          console.log("Error sending question:", error);
          ShowAlert("An Error Occurred!", "error");
          window.location.href = "/app/question";
        });
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <OutlinedInput
            fullWidth
            minRows={7}
            type="text"
            label="Type your Question..."
            {...getFieldProps("question")}
            error={Boolean(touched.question && errors.question)}
            helperText={touched.question && errors.question}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Submit
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

export default function AskQuestion() {
  return (
    <Card style={{ display: "inline-block" }}>
      <CardHeader title="Got a Question?" />
      <CardContent>
        <Typography variant="caption" sx={{ color: "text.secondary" }}>
          Type your Question....
        </Typography>
        <QuestionForm />
      </CardContent>
    </Card>
  );
}
