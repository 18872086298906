import { combineReducers } from "redux";
import signInSliceReducer from "../features/signIn/signInSlice";
import maleGuardianReducer from "../features/maleGuardian/maleGuardianSlice";
import femaleGuardianReducer from "../features/femaleGuardian/femaleGuardianSlice";
import childReducer from "../features/child/childSlice";
import allChildrenReducer from "../features/allChildren/allChildrenSlice";
import accountReducer from "../features/account/accountSlice";

const rootReducer = combineReducers({
  // reducers go here
  signIn: signInSliceReducer,
  maleGuardian: maleGuardianReducer,
  femaleGuardian: femaleGuardianReducer,
  childInfo: childReducer,
  allChildren: allChildrenReducer,
  account: accountReducer,
});

export default rootReducer;
