import PropTypes from "prop-types";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
// material
import { alpha, styled } from "@mui/material/styles";
import {
  Box,
  Grid,
  Stack,
  Avatar,
  Divider,
  Container,
  Typography,
  Card,
  Button,
  CardHeader,
  CardContent,
} from "@mui/material";
// material-lab
import {
  Timeline,
  TimelineItem,
  TimelineContent,
  TimelineSeparator,
  TimelineDot,
} from "@mui/lab";
// gql
import { useQuery } from "@apollo/client";
import { ADMIN_GET_USER } from "../gql/UserQueries";
// components
import Page from "../components/Page";
import Footer from "../components/Footer";
import { EditChild, UserGuardianDetails } from "../components/_dashboard/user";
// mock data
import { children } from "../_mocks_/account";

// ----------------------------------------------------------------------

const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(16),
  height: theme.spacing(16),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(
    theme.palette.primary.dark,
    0
  )} 0%, ${alpha(theme.palette.primary.dark, 0.24)} 100%)`,
}));

// ----------------------------------------------------------------------

ChildSideBar.propTypes = {
  child: PropTypes.object,
};

ChildDetails.propTypes = {
  child: PropTypes.object,
};

ChildAccountItem.propTypes = {
  keyName: PropTypes.string,
  child: PropTypes.object,
};

// Capitalize
function titleCase(str) {
  const splitStr = str.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i += 1) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
}

// get first letters of name
function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: 112,
      height: 112,
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

function ChildSideBar({ child }) {
  // get child id from url
  const { childId } = useParams();
  const { id } = useParams();
  const location = useLocation();

  if (child === null || child.name === null) {
    localStorage.clear();
    window.location.href = "/";
  }

  // get the pathname
  const childDetailsPage =
    location.pathname === `/dashboard/children/${id}/${childId}`;
  return (
    <Card>
      <CardHeader title="Child Profile" />
      <Box sx={{ px: 3, textAlign: "center", py: 1 }}>
        <IconWrapperStyle>
          <Avatar
            alt={titleCase(child.name)}
            {...stringAvatar(titleCase(child.name))}
          />
        </IconWrapperStyle>

        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {child.name}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {childDetailsPage && (
          <Stack spacing={1.5}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              style={{ textDecoration: "none", color: "inherit" }}
              href={`/dashboard/children/${id}/${child._id}/edit`}
            >
              Edit Child Info
            </Button>
          </Stack>
        )}
      </Box>
    </Card>
  );
}

function ChildAccountItem({ keyName, child }) {
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot
          sx={{
            bgcolor:
              (keyName === "Name" && "primary.main") ||
              (keyName === "DOB" && "success.main") ||
              (keyName === "School" && "info.main") ||
              (keyName === "Bio" && "error.main") ||
              (keyName === "Hobbies" && "primary.main"),
          }}
        />
      </TimelineSeparator>
      <TimelineContent>
        <Typography variant="subtitle2">{keyName}</Typography>
        <Typography variant="caption" sx={{ color: "text.secondary" }}>
          {child[keyName]}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
}

function ChildDetails({ child }) {
  return (
    <Card
      sx={{
        "& .MuiTimelineItem-missingOppositeContent:before": {
          display: "none",
        },
      }}
    >
      <CardContent>
        <Timeline>
          {Object.keys(child).map((key) => (
            <ChildAccountItem key={key} keyName={key} child={child} />
          ))}
        </Timeline>
      </CardContent>
    </Card>
  );
}

export default function AdminViewChild() {
  // get childId and id from url
  const { childId } = useParams();
  const { id } = useParams();

  const location = useLocation();
  // get the pathname
  const childDetailsPage =
    location.pathname === `/dashboard/children/${id}/${childId}`;
  const childEditPage =
    location.pathname === `/dashboard/children/${id}/${childId}/edit` ||
    location.pathname === `/dashboard/children/${id}/${childId}/edit/hobbies`;

  // query for all users
  const { loading, error, data } = useQuery(ADMIN_GET_USER, {
    variables: { id },
    errorPolicy: "ignore",
    fetchPolicy: "no-cache",
  });

  // if loading, show loading screen
  if (loading) {
    return <div>Loading...</div>;
  }

  // if error, log error and log out
  if (error) {
    console.log("Error Getting User: ", error);
    if (error.graphQLErrors.length > 0) {
      console.log(error.graphQLErrors[0].message);
      if (error.graphQLErrors[0].status === 401) {
        localStorage.clear();
        window.location.href = "/";
      }
    }
    console.log("Not GQL Error: ", error);
  }

  // render child side bar
  const renderChildSideBar = (data) => {
    const child = data.adminGetUser.children.find(
      (child) => child._id === childId
    );
    return <ChildSideBar child={child} />;
  };

  // render child details
  const renderChildDetails = (data) => {
    const childAcc = data.adminGetUser.children.find(
      (child) => child._id === childId
    );
    const child = {
      Name: titleCase(childAcc.name),
      DOB: childAcc.dob,
      School: childAcc.school,
      Bio: childAcc.bio,
      Hobbies: childAcc.hobbies.join(", "),
    };
    return <ChildDetails child={child} />;
  };

  // render edit child
  const renderEditChild = (data) => {
    const child = data.adminGetUser.children.find(
      (child) => child._id === childId
    );
    return <EditChild child={child} />;
  };

  return (
    <Page title="Child Profile | Hodari Junior Club">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Profile</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            {data &&
              data.adminGetUser &&
              childDetailsPage &&
              renderChildSideBar(data)}
          </Grid>

          {data && data.adminGetUser && childDetailsPage && (
            <Grid item xs={12} md={6} lg={8}>
              <Card>
                <CardHeader title="Child Details" />
                <CardContent>
                  <Grid container spacing={2}>
                    {renderChildDetails(data)}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}

          <Grid item xs={12} md={6} lg={6}>
            {data &&
              data.adminGetUser &&
              childEditPage &&
              renderEditChild(data)}
          </Grid>

          {data && data.adminGetUser && childDetailsPage && (
            <Grid item xs={12} md={6} lg={8}>
              <UserGuardianDetails data={data.adminGetUser} />
            </Grid>
          )}
        </Grid>

        <Footer />
      </Container>
    </Page>
  );
}
