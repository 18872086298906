// material
import { Box, Grid, Container, Typography } from "@mui/material";
// gql
import { useQuery } from "@apollo/client";
import { GET_USER } from "../gql/UserQueries";
// components
import Page from "../components/Page";
import { UserEdit, UserSideBar } from "../components/_dashboard/user";
import Footer from "../components/Footer";

// ----------------------------------------------------------------------

export default function EditUserProfile() {
  // get values from local storage
  const id = localStorage.getItem("id");

  // query for all users
  const { loading, error, data } = useQuery(GET_USER, {
    variables: { id },
    errorPolicy: "ignore",
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      console.log("Data: ");
      console.log(data.getUser._id);
    },
    onError: (error) => {
      console.log("Error: ");
      console.log(error);
      if (error.graphQLErrors.length > 0) {
        console.log(error.graphQLErrors[0].message);
        if (error.graphQLErrors[0].status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      }
    },
  });
  if (error) {
    console.log("Error Getting User: ", error);
    if (error.graphQLErrors.length > 0) {
      console.log(error.graphQLErrors[0].message);
      if (error.graphQLErrors[0].status === 401) {
        localStorage.clear();
        window.location.href = "/";
      }
    }
    console.log("Not GQL Error: ", error);
  }

  const logOut = (data) => {
    if (
      (data === undefined ||
        data.getUser === undefined ||
        data.getUser === null ||
        data.getUser === "" ||
        data.getUser.name === null ||
        data.getUser.name === undefined) &&
      !loading
    ) {
      localStorage.clear();
      window.location.href = "/";
    }
  };

  return (
    <Page title="Edit Profile | Hodari Junior Club">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Profile</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            {data ? <UserSideBar data={data.getUser} /> : logOut(data)}
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            {data ? <UserEdit user={data.getUser} /> : logOut(data)}
          </Grid>
        </Grid>

        <Footer />
      </Container>
    </Page>
  );
}
