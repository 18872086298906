// material
import {
  Box,
  Grid,
  Container,
  Typography,
  Skeleton,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";
// gql
import { useQuery } from "@apollo/client";
import { GET_USER } from "../gql/UserQueries";
// components
import Page from "../components/Page";
import { UserSideBar, UserChangePassword } from "../components/_dashboard/user";
import Footer from "../components/Footer";

// ----------------------------------------------------------------------

export default function ChangeUserPassword() {
  // get values from local storage
  const id = localStorage.getItem("id");

  // query for all users
  const { loading, error, data } = useQuery(GET_USER, {
    variables: { id },
    errorPolicy: "ignore",
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      console.log("Data: ");
      console.log(data.getUser);
    },
    onError: (error) => {
      console.log("Error: ");
      console.log(error);
      if (error.graphQLErrors.length > 0) {
        console.log(error.graphQLErrors[0].message);
        if (error.graphQLErrors[0].status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      }
    },
  });
  if (error) {
    console.log("Error Getting User: ", error);
    if (error.graphQLErrors.length > 0) {
      console.log(error.graphQLErrors[0].message);
      if (error.graphQLErrors[0].status === 401) {
        localStorage.clear();
        window.location.href = "/";
      }
    }
    console.log("Not GQL Error: ", error);
  }

  const renderUser = (data) => {
    if (data) {
      return <UserSideBar data={data.getUser} />;
    }
    logOut(data);
  };

  const logOut = (data) => {
    if (
      (data === undefined ||
        data.getUser === undefined ||
        data.getUser === null ||
        data.getUser === "" ||
        data.getUser.name === null ||
        data.getUser.name === undefined) &&
      !loading
    ) {
      localStorage.clear();
      window.location.href = "/";
    }
  };

  return (
    <Page title="Change Password | Hodari Junior Club">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Profile</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            {loading ? (
              <Card>
                <CardHeader />
                <CardContent>
                  <Skeleton variant="rectangular" width="100%" height="900" />
                </CardContent>
              </Card>
            ) : (
              renderUser(data)
            )}
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            {loading ? (
              <Card>
                <CardHeader />
                <CardContent>
                  <Skeleton variant="rectangular" width="100%" height="900" />
                </CardContent>
              </Card>
            ) : (
              <UserChangePassword />
            )}
          </Grid>
        </Grid>

        {loading ? <Skeleton width="100%" /> : <Footer />}
      </Container>
    </Page>
  );
}
