import * as Yup from "yup";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// material
import { Stack, TextField, IconButton, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";

// graphql
import { useMutation } from "@apollo/client";
import { SET_PASSWORD_QUERY } from "../../../gql/UserQueries";
import { gqlError } from "../../../gql/gqlError";

// alerts
import { ShowAlert } from "../../Alert";

// ----------------------------------------------------------------------

export const ChangePasswordForm = () => {
  // get url parameters
  const { token } = useParams();

  // password visibility
  const [showPassword, setShowPassword] = useState(false);

  // schema for validation
  const ChangePasswordSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .required("Password is required")
      .test(
        "passwords-match",
        "Passwords must match",
        (value, context) => context.parent.newPassword === value
      ),
  });

  // query for setting a new password
  const [setPassword] = useMutation(SET_PASSWORD_QUERY, {
    errorPolicy: "all",
    onError: gqlError,
  });

  // capture login data using formik
  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
      token,
    },
    validationSchema: ChangePasswordSchema,
    onSubmit: () => {
      // password input
      const newPasswordInput = {
        password: values.confirmPassword,
        token: values.token,
      };

      // set new Password to the gpl server
      setPassword({ variables: { newPasswordInput } })
        .then(({ data }) => {
          console.log("Setting new password >>>>");
          // check for false
          if (data.setNewPassword === false) {
            console.log("Error setting password");
            ShowAlert(
              "Link may have already expired. Try again. Redirecting to Sign In Page",
              "warning"
            );
            window.location = "/login";
          }

          if (data.setNewPassword) {
            console.log("Password has been reset successfully");
            ShowAlert(
              "Password has been reset successfully; Redirecting to Sign In Page...",
              "success"
            );
            window.location = "/login";
          }
        })
        .catch((err) => {
          console.log("sending new password mutation error");
          console.log(err);
          ShowAlert(
            "Link may have already expired. Try again. Redirecting to Sign In Page...",
            "warning"
          );
          window.location = "/login";
        });
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            type={showPassword ? "text" : "password"}
            label="New Password"
            {...getFieldProps("newPassword")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.newPassword && errors.newPassword)}
            helperText={touched.newPassword && errors.newPassword}
          />

          <TextField
            fullWidth
            type={showPassword ? "text" : "password"}
            label="Confirm New Password"
            {...getFieldProps("confirmPassword")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            helperText={touched.confirmPassword && errors.confirmPassword}
          />

          <LoadingButton
            fullWidth
            padd
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Change Password
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
};
