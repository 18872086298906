// material
import { Box, Grid, Container, Typography } from "@mui/material";
// components
import Page from "../components/Page";
import { AskQuestion } from "../components/_dashboard/user";
import Footer from "../components/Footer";

// ----------------------------------------------------------------------

export default function Question() {
  return (
    <Page title="Ask Help | Hodari Junior Club">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Help</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <AskQuestion />
          </Grid>
        </Grid>

        <Footer />
      </Container>
    </Page>
  );
}
