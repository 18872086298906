import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
// material
import { alpha, styled } from "@mui/material/styles";
import {
  Box,
  Card,
  Stack,
  Avatar,
  Button,
  Divider,
  CardHeader,
  Typography,
} from "@mui/material";

// ----------------------------------------------------------------------

const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(16),
  height: theme.spacing(16),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(
    theme.palette.primary.dark,
    0
  )} 0%, ${alpha(theme.palette.primary.dark, 0.24)} 100%)`,
}));

// ----------------------------------------------------------------------

UserSideBar.propTypes = {
  data: PropTypes.object,
};

// Capitalize
function titleCase(str) {
  const splitStr = str.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i += 1) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
}

// get first letters of name
function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: 112,
      height: 112,
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

export default function UserSideBar({ data }) {
  // log out if no data
  if (data === null || data.name === null || data.parents === null) {
    localStorage.clear();
    window.location.href = "/";
  }

  // get current user role
  const role = localStorage.getItem("role");

  // location instance
  const location = useLocation();

  // get the user pathname
  const editPage = location.pathname === "/app/profile/edit";
  const changePasswordPage =
    location.pathname === "/app/profile/change-password";

  // admin side of viewing user profile
  const adminSideViewingUserProfilePage =
    location.pathname === `/dashboard/users/${data._id}`;

  return (
    <Card>
      <CardHeader title="Account Details" />
      <Box sx={{ px: 3, textAlign: "center", py: 1 }}>
        <IconWrapperStyle>
          <Avatar
            {...stringAvatar(titleCase(data.name))}
            alt={titleCase(data.name)}
          />
        </IconWrapperStyle>

        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {titleCase(data.name)}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {data.phone}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {data.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        <Stack spacing={1.5}>
          {!editPage && role === "PARENT" && (
            <Button
              fullWidth
              size="large"
              variant="contained"
              style={{ textDecoration: "none", color: "inherit" }}
              href="/app/profile/edit"
            >
              Edit Profile
            </Button>
          )}

          {!changePasswordPage && role === "PARENT" && (
            <Button
              fullWidth
              size="large"
              variant="contained"
              style={{ textDecoration: "none", color: "inherit" }}
              href="/app/profile/change-password"
            >
              Change Password
            </Button>
          )}

          {adminSideViewingUserProfilePage && role === "ADMIN" && (
            <>
              <Button
                fullWidth
                size="large"
                variant="contained"
                style={{ textDecoration: "none", color: "inherit" }}
                href={`/dashboard/users/${data._id}/edit`}
              >
                Edit Profile
              </Button>
              <Button
                fullWidth
                size="large"
                variant="contained"
                style={{ textDecoration: "none", color: "inherit" }}
                href={`/dashboard/users/${data._id}/change-password`}
              >
                Change Password
              </Button>
            </>
          )}
        </Stack>
      </Box>
    </Card>
  );
}
