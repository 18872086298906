import PropTypes from "prop-types";
import { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import {
  Box,
  Link,
  Button,
  Drawer,
  Typography,
  Avatar,
  Stack,
} from "@mui/material";

// components
import Logo from "../../components/Logo";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
import { MHidden } from "../../components/@material-extend";
//
import sidebarConfig from "./SidebarConfig";
import account from "../../_mocks_/account";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200],
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();

  // get current logged in role
  const role = localStorage.getItem("role");

  // capture if user is logged in
  const session = localStorage.getItem("hodari-session");
  let isLoggedIn = false;
  switch (session) {
    case "true":
      isLoggedIn = true;
      break;
    case "false":
      isLoggedIn = false;
      break;
    case "undefined":
      isLoggedIn = false;
      break;
    case "":
      isLoggedIn = false;
      break;
    case null:
      isLoggedIn = false;
      break;
    default:
      isLoggedIn = false;
      break;
  }

  /* const [user, setUser] = useState({});

  // id from localStorage
  const id = localStorage.getItem("id"); */

  // query for current users
  /* const { loading, error, data } = useQuery(GET_USER, {
    variables: { id },
    errorPolicy: "ignore",
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      console.log("GET_USER >>>>");
      console.log(data);
      setUser(data.getUser);
    },
  });

  // handle errors
  if (error) {
    console.log(error);
  } */

  // if loading, show loading due to eslinting visible={true}
  // const queryLoading = <Spinner radius={120} color="#333" stroke={2} visible />;

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: "100%",
        "& .simplebar-content": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box component={RouterLink} to="/" sx={{ display: "inline-flex" }}>
          <Logo />
        </Box>
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle>
            {/* {isLoggedIn && <Avatar src={account.photoURL} alt="photoURL" />} */}
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                {/* {data === null ? account.displayName : data.getUser.name} */}
                {!isLoggedIn ? account.displayName : "Welcome to Hodari"}
              </Typography>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {isLoggedIn ? account.role : "Guest"}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>

      <NavSection navConfig={sidebarConfig} />

      <Box sx={{ flexGrow: 1 }} />

      {!isLoggedIn && (
        <>
          <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
            <Stack
              alignItems="center"
              spacing={3}
              sx={{
                p: 2.5,
                pt: 5,
                borderRadius: 2,
                position: "relative",
                bgcolor: "grey.200",
              }}
            >
              <Box
                component="img"
                src="/static/illustrations/illustration_avatar.png"
                sx={{ width: 100, position: "absolute", top: -50 }}
              />

              <Box sx={{ textAlign: "center" }}>
                <Typography gutterBottom variant="h6">
                  Want to Know more?
                </Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  Join Us
                </Typography>
              </Box>

              <Button
                fullWidth
                href="/register"
                target="_blank"
                variant="contained"
              >
                Register Today
              </Button>
            </Stack>
          </Box>
        </>
      )}
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
