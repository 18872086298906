import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
// material
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
};

export default function Logo({ sx }) {
  const location = useLocation();
  // get the pathname
  const isNotloginPage = location.pathname !== `/login`;
  const isNotregisterPage = location.pathname !== `/register`;
  const isNotGuardian2Page = location.pathname !== `/register/guardian2`;
  const isNotChildPage = location.pathname !== `/register/child`;
  const isNotHobbiesPage = location.pathname !== `/register/hobbies`;
  const isNotCompletePage = location.pathname !== `/register/complete`;

  return (
    isNotloginPage &&
    isNotregisterPage &&
    isNotGuardian2Page &&
    isNotChildPage &&
    isNotHobbiesPage &&
    isNotCompletePage && (
      <Box
        component="img"
        src="https://edwinnduti.mo.cloudinary.net/hodari-badge.png"
        sx={{ width: "auto", height: 80, ...sx }}
      />
    )
  );
}
