import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { useNavigate } from "react-router-dom";
// material
import { Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";

// redux
import { useSelector, useDispatch } from "react-redux";
import { addChild } from "../../../features/child/childSlice";

// ----------------------------------------------------------------------

export const ChildRegisterForm = () => {
  const navigate = useNavigate();

  // read initial data from rootreducer using useSelector hook
  const childInfo = useSelector((state) => state.childInfo.childsInfo);

  const dispatch = useDispatch();

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("First name required"),
    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Last name required"),
    dob: Yup.string().required("Date of birth required"),
    school: Yup.string().required("School required"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: childInfo.childsFirstName,
      lastName: childInfo.childsLastName,
      dob: childInfo.childsDob,
      school: childInfo.childsSchool,
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      const childsData = {
        childsFirstName: values.firstName,
        childsLastName: values.lastName,
        childsName: values.firstName.concat(" ", values.lastName),
        childsDob: values.dob,
        childsSchool: values.school,
      };

      // add childs details to redux store
      dispatch(addChild(childsData));
      navigate("/register/hobbies");
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <TextField
              fullWidth
              label="First name"
              {...getFieldProps("firstName")}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />

            <TextField
              fullWidth
              label="Last name"
              {...getFieldProps("lastName")}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>

          <TextField
            fullWidth
            type="date"
            label="Birthday"
            {...getFieldProps("dob")}
            error={Boolean(touched.dob && errors.dob)}
            helperText={touched.dob && errors.dob}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            fullWidth
            type="text"
            label="School"
            {...getFieldProps("school")}
            error={Boolean(touched.school && errors.school)}
            helperText={touched.school && errors.school}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Next
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
};
