import { Icon } from "@iconify/react";
import pieChart2Fill from "@iconify/icons-eva/pie-chart-2-fill";
import peopleFill from "@iconify/icons-eva/people-fill";
import peopleOutline from "@iconify/icons-eva/people-outline";
import personFill from "@iconify/icons-eva/person-fill";
import personAddOutline from "@iconify/icons-eva/person-add-outline";
import shoppingBagFill from "@iconify/icons-eva/shopping-bag-fill";
import fileTextFill from "@iconify/icons-eva/file-text-fill";
import lockFill from "@iconify/icons-eva/lock-fill";
import personAddFill from "@iconify/icons-eva/person-add-fill";

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const role = localStorage.getItem("role");
const session = localStorage.getItem("hodari-session");

// unlogged in user
const unLoggedInSidebarConfig = [
  {
    title: "login",
    path: "/login",
    icon: getIcon(lockFill),
  },
  {
    title: "register",
    path: "/register",
    icon: getIcon(personAddFill),
  },
];

// user sdebar when logged in
const parentLoggedInSidebarConfig = [
  {
    title: "children",
    path: "/app/children",
    icon: getIcon(peopleFill),
  },
  {
    title: "profile",
    path: "/app/profile",
    icon: getIcon(personFill),
  },
  {
    title: "add Child",
    path: "/app/add-child",
    icon: getIcon(personAddOutline),
  },
];

// admin sidebar when logged in
const adminLoggedInSidebarConfig = [
  {
    title: "dashboard",
    path: "/dashboard/app",
    icon: getIcon(pieChart2Fill),
  },
  {
    title: "users",
    path: "/dashboard/users",
    icon: getIcon(peopleFill),
  },
  {
    title: "children",
    path: "/dashboard/children",
    icon: getIcon(peopleOutline),
  },
  {
    title: "new user",
    path: "/dashboard/adduser",
    icon: getIcon(personAddFill),
  },
  /* {
    title: "product",
    path: "/dashboard/products",
    icon: getIcon(shoppingBagFill),
  },
  {
    title: "blog",
    path: "/dashboard/blog",
    icon: getIcon(fileTextFill),
  }, */
];

function getSidebarConfig(role, session) {
  if (role === "ADMIN" && session === "true") {
    return adminLoggedInSidebarConfig;
  }
  if (role === "PARENT" && session === "true") {
    return parentLoggedInSidebarConfig;
  }
  return unLoggedInSidebarConfig;
}

const sidebarConfig = getSidebarConfig(role, session);

export default sidebarConfig;
