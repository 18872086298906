// ----------------------------------------------------------------------

const account = {
  displayName: "Jesse Jackson",
  email: "jessejackson@gmail.com",
  phoneNumber: "0706119340",
  photoURL: "/static/mock-images/avatars/avatar_default.jpg",
  bio: "I like coding, preferrably with JavaScript and playing the piano when idle.",
};

const children = [
  {
    id: 1,
    name: "John Lew",
    age: "12",
    dob: "12/12/2012",
    hobbies: ["coding", "playing piano"],
    school: "Hodari Junior Club",
  },
  {
    id: 2,
    name: "Mike Ronald",
    age: "15",
    dob: "12/12/2012",
    hobbies: ["coding", "playing piano"],
    school: "Hodari Junior Club",
  },
  {
    id: 3,
    name: "Mary Jane",
    age: "10",
    dob: "12/12/2012",
    hobbies: ["coding", "playing piano"],
    school: "Hodari Junior Club",
  },
  {
    id: 4,
    name: "John Doe",
    age: "12",
    dob: "12/12/2012",
    hobbies: ["coding", "playing piano"],
    school: "Hodari Junior Club",
  },
  {
    id: 5,
    name: "Lewis Hay",
    age: "12",
    dob: "12/12/2012",
    hobbies: ["coding", "playing piano"],
    school: "Hodari Junior Club",
  },
  {
    id: 6,
    name: "Donald Kim",
    age: "15",
    dob: "12/12/2012",
    hobbies: ["coding", "playing piano"],
    school: "Hodari Junior Club",
  },
  {
    id: 7,
    name: "Richard Lee",
    age: "14",
    dob: "13/1/2012",
    hobbies: ["coding", "playing piano"],
    school: "Hodari Junior Club",
  },
  {
    id: 8,
    name: "Jackson Manuel",
    age: "11",
    dob: "13/1/2003",
    hobbies: ["coding", "playing piano"],
    school: "Hodari Junior Club",
  },
];

export default account;
export { children };
