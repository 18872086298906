import { Icon } from "@iconify/react";
import PropTypes from "prop-types";
import { useRef, useState } from "react";
import editFill from "@iconify/icons-eva/edit-fill";
import { Link as RouterLink, useLocation } from "react-router-dom";
import trash2Outline from "@iconify/icons-eva/trash-2-outline";
import moreVerticalFill from "@iconify/icons-eva/more-vertical-fill";
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
// alerts
import Swal from "sweetalert2";
// gql
import { useMutation } from "@apollo/client";
import {
  DEACTIVATE_USER_QUERY,
  DEACTIVATE_CHILD_QUERY,
} from "../../../gql/UserQueries";

// ----------------------------------------------------------------------

UserMoreMenu.propTypes = {
  userId: PropTypes.string,
  childId: PropTypes.string,
};

export default function UserMoreMenu({ userId, childId }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  // gql query
  const [deactivateUser] = useMutation(DEACTIVATE_USER_QUERY);
  const [deactivateChild] = useMutation(DEACTIVATE_CHILD_QUERY);

  // get current location
  const location = useLocation();
  const currentUsersLocation =
    location.pathname === "/dashboard/users" ? "/dashboard/users" : "/";
  const currentChildrenLocation =
    location.pathname === "/dashboard/children" ? "/dashboard/children" : "/";
  const isChildrenPage = location.pathname === "/dashboard/children";

  const handleDeacivateUserAlert = (
    userId,
    currentLocation = currentUsersLocation
  ) => {
    setIsOpen(false);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to reverse this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, deactivate it!",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        console.log("userId", userId);
        try {
          const deactivateUserInput = {
            userId,
            isDeleted: true,
          };
          const { data } = await deactivateUser({
            variables: { deactivateUserInput },
          });
          console.log("data", data);
          if (data) {
            console.log(`User Account ${userId} Deactivated`);
            return { isDeleted: data.deactivateUser };
          }
          if (!data) {
            console.log(`User Account ${userId} not Deactivated`);
            return { isDeleted: data.deactivateUser };
          }
        } catch (error) {
          console.log("Error Deactivating User: ", error);
          return { isDeleted: null };
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value.isDeleted) {
          Swal.fire("Deleted!", "Account has been deactivated.", "success");
          window.location.href = currentLocation;
        }
        if (!result.value.isDeleted) {
          Swal.fire(
            "Error!",
            "Account has not been deactivated.Try again later or reload page.",
            "error"
          );
          window.location.href = currentLocation;
        }
        if (
          result.value.isDeleted === null ||
          result.value.isDeleted === undefined
        ) {
          Swal.fire(
            "Error!",
            "Account does not exist. Try reloading page.",
            "error"
          );
          window.location.href = currentLocation;
        }
      }
    });
  };

  // deactivate child
  const handleDeacivateChildAlert = (
    userId,
    childId,
    currentLocation = currentChildrenLocation
  ) => {
    setIsOpen(false);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to reverse this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, deactivate it!",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        console.log(`userId: ${userId}/n childId: ${childId}`);
        try {
          const deactivateChildInput = {
            userId,
            childId,
            isDeleted: true,
          };
          const { data } = await deactivateChild({
            variables: { deactivateChildInput },
          });
          console.log("data", data);
          if (data) {
            console.log(`Child Account ${childId} Deactivated`);
            return { isDeleted: data.deactivateChild };
          }
          if (!data) {
            console.log(`Child Account ${childId} not Deactivated`);
            return { isDeleted: data.deactivateChild };
          }
        } catch (error) {
          console.log("Error Deactivating Child: ", error);
          return { isDeleted: null };
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value.isDeleted) {
          Swal.fire("Deleted!", "Account has been deactivated.", "success");
          window.location.href = currentLocation;
        }
        if (!result.value.isDeleted) {
          Swal.fire(
            "Error!",
            "Account has not been deactivated.Try again later or reload page.",
            "error"
          );
          window.location.href = currentLocation;
        }
        if (
          result.value.isDeleted === null ||
          result.value.isDeleted === undefined
        ) {
          Swal.fire(
            "Error!",
            "Account does not exist. Try reloading page.",
            "error"
          );
          window.location.href = currentLocation;
        }
      }
    });
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          onClick={() =>
            isChildrenPage
              ? handleDeacivateChildAlert(userId, childId)
              : handleDeacivateUserAlert(userId)
          }
          sx={{ color: "text.secondary" }}
        >
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Deactivate"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>

        <MenuItem
          component={RouterLink}
          to={
            isChildrenPage
              ? `/dashboard/children/${userId}/${childId}`
              : `/dashboard/users/${userId}`
          }
          sx={{ color: "text.secondary" }}
        >
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="View"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
      </Menu>
    </>
  );
}
