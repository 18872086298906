import PropTypes from "prop-types";
// material
import {
  Card,
  Button,
  CardContent,
  CardMedia,
  Typography,
  CardActionArea,
} from "@mui/material";

// ----------------------------------------------------------------------

Child.propTypes = {
  child: PropTypes.object,
  index: PropTypes.number,
};

export default function Child({ child, index }) {
  // log out if no data
  if (child === null || child.name === null) {
    localStorage.clear();
    window.location.href = "/";
  }

  // get values from local storage
  const role = localStorage.getItem("role");

  // location to move to
  const location =
    role === "ADMIN"
      ? `/dashboard/children/${child.account._id}/${child._id}`
      : `/app/children/${child._id}`;

  return (
    <Card>
      <CardActionArea>
        <CardMedia
          component="img"
          height="150"
          image={`https://picsum.photos/200?random=${index}`}
          alt={child._id}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            <Button href={location} onClick={() => console.log(child)}>
              {child.name}
            </Button>
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
