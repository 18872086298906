import { createSlice } from "@reduxjs/toolkit";
import { rootState } from "../../app/rootState";

const initialState = rootState.credentials;

const signInSlice = createSlice({
  name: "signIn",
  initialState,
  reducers: {
    signIn: (state, action) => {
      state.email = action.payload.email;
      state.password = action.payload.password;
      state.remember = action.payload.remember;
      return state;
    },
  },
});

export const { signIn } = signInSlice.actions;

export default signInSlice.reducer;
