import * as Yup from "yup";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// material
import {
  Stack,
  TextField,
  Typography,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

// graphql
import { useMutation } from "@apollo/client";
import { ADMIN_CHANGE_USER_PASSWORD_QUERY } from "../../../gql/UserQueries";
import { gqlError } from "../../../gql/gqlError";

// alerts
import { ShowAlert } from "../../Alert";

// ----------------------------------------------------------------------

export default function AdminUserChangePassword() {
  // get id
  const { id } = useParams();

  // password visibility
  const [showPassword, setShowPassword] = useState(false);

  // schema for validation
  const LoginSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .required("Password is required")
      .test(
        "passwords-match",
        "Passwords must match",
        (value, context) => context.parent.newPassword === value
      ),
  });

  // query for changing a new password
  const [adminChangeUserPassword] = useMutation(
    ADMIN_CHANGE_USER_PASSWORD_QUERY,
    {
      errorPolicy: "all",
      onError: gqlError,
    }
  );

  // capture login data using formik
  const formik = useFormik({
    initialValues: {
      oldPassword: "dont_enter_password_here", // dont enter password here and dont leave it blank
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      // password input
      const changePasswordInput = {
        userId: id,
        currentPassword: values.oldPassword,
        newPassword: values.newPassword,
      };

      // change new Password to the gpl server
      adminChangeUserPassword({ variables: { changePasswordInput } })
        .then(({ data }) => {
          console.log("Changing password >>>>");
          // check for false
          if (data.adminChangeUserPassword === false) {
            console.log("Error setting password");
            ShowAlert("Error while changing password", "warning");
            window.location = `/dashboard/users/${id}/change-password`;
          }

          if (data.adminChangeUserPassword) {
            console.log("Password has been changed successfully");
            ShowAlert(
              "Password has been changed successfully; Redirecting to Profile Page...",
              "success"
            );
            window.location = `/dashboard/users/${id}`;
          }
        })
        .catch((err) => {
          console.log("sending new password mutation error");
          console.log(err);
          ShowAlert("Error while changing password", "warning");
          window.location = `/dashboard/users/${id}/change-password`;
        });
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <Card>
      <CardHeader title="Change Account Password" />
      <CardContent>
        <Typography variant="caption" sx={{ color: "text.secondary" }}>
          Ensure you add a secure password.
        </Typography>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={4}>
              <TextField
                fullWidth
                type={showPassword ? "text" : "password"}
                label="New Password"
                {...getFieldProps("newPassword")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(touched.newPassword && errors.newPassword)}
                helperText={touched.newPassword && errors.newPassword}
              />

              <TextField
                fullWidth
                type={showPassword ? "text" : "password"}
                label="Confirm New Password"
                {...getFieldProps("confirmPassword")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(
                  touched.confirmPassword && errors.confirmPassword
                )}
                helperText={touched.confirmPassword && errors.confirmPassword}
              />

              <LoadingButton
                fullWidth
                padd
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Change Password
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </CardContent>
    </Card>
  );
}
