import * as Yup from "yup";
import { useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
// material
import {
  Box,
  Grid,
  Stack,
  TextField,
  Container,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Chip,
  MenuItem,
  Checkbox,
  ListItemText,
  InputLabel,
  OutlinedInput,
  Select,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// gql
import { useMutation } from "@apollo/client";
// redux
import { useSelector, useDispatch } from "react-redux";
import { addChild, addChildsOtherDetails } from "../features/child/childSlice";
// gqi err
import { gqlError } from "../gql/gqlError";
import { ADDED_CHILD_QUERY } from "../gql/UserQueries";
// components
import Page from "../components/Page";
// mock data
import { hobbies } from "../_mocks_/hobbies";

// ----------------------------------------------------------------------

// constants
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// ----------------------------------------------------------------------

// child details form
function ChildRegisterForm() {
  const navigate = useNavigate();

  // read initial data from rootreducer using useSelector hook
  const childInfo = useSelector((state) => state.childInfo.childsInfo);

  const dispatch = useDispatch();

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("First name required"),
    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Last name required"),
    dob: Yup.string().required("Date of birth required"),
    school: Yup.string().required("School required"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: childInfo.childsFirstName,
      lastName: childInfo.childsLastName,
      dob: childInfo.childsDob,
      school: childInfo.childsSchool,
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      const childsData = {
        childsFirstName: values.firstName,
        childsLastName: values.lastName,
        childsName: values.firstName.concat(" ", values.lastName),
        childsDob: values.dob,
        childsSchool: values.school,
      };

      // add childs details to redux store
      dispatch(addChild(childsData));
      navigate("/app/add-child/hobbies");
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <TextField
              fullWidth
              label="First name"
              {...getFieldProps("firstName")}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />

            <TextField
              fullWidth
              label="Last name"
              {...getFieldProps("lastName")}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>

          <TextField
            fullWidth
            type="date"
            label="Birthday"
            {...getFieldProps("dob")}
            error={Boolean(touched.dob && errors.dob)}
            helperText={touched.dob && errors.dob}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            fullWidth
            type="text"
            label="School"
            {...getFieldProps("school")}
            error={Boolean(touched.school && errors.school)}
            helperText={touched.school && errors.school}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Next
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

// child hobbies form
function ChildHobbies() {
  const navigate = useNavigate();
  const [personHobby, setPersonHobby] = useState([]);

  // get user id
  const id = localStorage.getItem("id");

  // read initial data from root state using useSelector hook
  const childInfo = useSelector((state) => state.childInfo.childsInfo);
  console.log("childInfo: ", childInfo);

  // get mutation query
  const [addChild, { error }] = useMutation(ADDED_CHILD_QUERY, {
    errorPolicy: "all",
    onError: gqlError,
  });

  if (error) {
    console.log("Getting AddChild Query Error: ", error);
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonHobby(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const RegisterSchema = Yup.object().shape({
    bio: Yup.string().required("Bio required"),
    hobbies: Yup.array().required("Hobbies required"),
  });

  const formik = useFormik({
    initialValues: {
      hobbies: childInfo.childsHobbies,
      bio: childInfo.childsBio,
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      const addChildInput = {
        guardianId: id,
        name: childInfo.childsName,
        school: childInfo.childsSchool,
        imageUrl: childInfo.childsImage,
        dob: childInfo.childsDob,
        hobbies: personHobby,
        bio: values.bio,
      };

      // send to server
      addChild({ variables: { addChildInput } })
        .then(({ data }) => {
          if (data) {
            console.log("Adding Child Data...");
            console.log(data.addChild);
            navigate("/app/children");
          }
        })
        .catch((err) => {
          console.log("logging error data");
          console.log(`ERROR!!!: ${err}`);
        });
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <InputLabel id="multiple-chip-label">Select Hobbies</InputLabel>
          <Select
            labelId="multiple-chip-label"
            id="multiple-chip"
            multiple
            value={personHobby}
            onChange={handleChange}
            input={<OutlinedInput />}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            <MenuItem disabled value="">
              <em>Choose your Hobbies</em>
            </MenuItem>
            {hobbies.map((hobby) => (
              <MenuItem key={hobby} value={hobby}>
                <Checkbox checked={personHobby.indexOf(hobby) > -1} />
                <ListItemText primary={hobby} />
              </MenuItem>
            ))}
          </Select>

          <TextField
            fullWidth
            label="Bio"
            {...getFieldProps("bio")}
            error={Boolean(touched.bio && errors.bio)}
            helperText={touched.bio && errors.bio}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Save
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

export default function AddChild() {
  const location = useLocation();
  // get the pathname
  const addChild = location.pathname === "/app/add-child";
  const addHobbies = location.pathname === "/app/add-child/hobbies";
  return (
    <Page title="Add Child | Hodari Junior Club">
      <Container maxWidth="xl">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={12} md={6} lg={6}>
            <Card style={{ display: "inline-block" }}>
              <CardHeader title="Add Child" />
              <CardContent>
                <Typography variant="caption" sx={{ color: "text.secondary" }}>
                  Add a new boy into your Hodari Junior Club account
                </Typography>
                {addChild && <ChildRegisterForm />}
                {addHobbies && <ChildHobbies />}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
