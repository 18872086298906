import PropTypes from "prop-types";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { useNavigate } from "react-router-dom";
// material
import {
  Stack,
  TextField,
  Typography,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// gql
import { useMutation } from "@apollo/client";
import { gqlError } from "../../../gql/gqlError";
import { UPDATE_USER_PROFILE_QUERY } from "../../../gql/UserQueries";

// ----------------------------------------------------------------------

UserEdit.propTypes = {
  user: PropTypes.object,
};

export default function UserEdit({ user }) {
  // log out if no data
  if (user === null || user.name === null) {
    localStorage.clear();
    window.location.href = "/";
  }

  // navigator instance
  const navigate = useNavigate();

  // get values from local storage
  const id = localStorage.getItem("id");

  // separate name in user
  const nameArray = user.name.split(" ");

  // get mutation query
  const [updateUser, { error }] = useMutation(UPDATE_USER_PROFILE_QUERY, {
    errorPolicy: "all",
    onError: gqlError,
  });

  if (error) {
    console.log("Getting UpdateUser Query Error: ", error);
  }

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("First name required"),
    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Last name required"),
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    phoneNumber: Yup.string()
      .min(5, "Too Short!")
      .required("Phone number is required,"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: nameArray[0],
      lastName: nameArray[1],
      email: user.email,
      phoneNumber: user.phone,
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      const updateUserInput = {
        userId: id,
        name: values.firstName.concat(" ", values.lastName),
        email: values.email,
        phone: values.phoneNumber,
        roles: "PARENT",
      };
      console.log("updateUserInput: ", updateUserInput);

      updateUser({ variables: { updateUserInput } })
        .then(({ data }) => {
          console.log("Update user...");
          if (data) {
            console.log("Updating Data...");
            console.log(data.updateUser);
            navigate("/app/profile");
          }
        })
        .catch((err) => {
          console.log("logging error data");
          console.log(`ERROR!!!: ${err}`);
        });
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  return (
    <Card>
      <CardHeader title="Guardian(s) Details" />
      <CardContent>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
                <TextField
                  fullWidth
                  label="First name"
                  {...getFieldProps("firstName")}
                  error={Boolean(touched.firstName && errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                />

                <TextField
                  fullWidth
                  label="Last name"
                  {...getFieldProps("lastName")}
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />
              </Stack>

              <TextField
                fullWidth
                autoComplete="email"
                type="email"
                label="Email address"
                {...getFieldProps("email")}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />

              <TextField
                fullWidth
                type="text"
                label="Phone number"
                {...getFieldProps("phoneNumber")}
                error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                helperText={touched.phoneNumber && errors.phoneNumber}
              />

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Save
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </CardContent>
    </Card>
  );
}
