import { makeVar, InMemoryCache } from "@apollo/client";

export const isLoggedInVar = makeVar(true);

export const accountVar = makeVar(null);

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        isLoggedIn: {
          read() {
            return localStorage.getItem("hodari-session");
          },
        },
        id: {
          read() {
            return localStorage.getItem("id");
          },
        },
        account: {
          read() {
            return accountVar();
          },
        },
      },
    },
  },
});
