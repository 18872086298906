import * as Yup from "yup";
import { useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import { useNavigate } from "react-router-dom";
// material
import {
  Box,
  Chip,
  Stack,
  TextField,
  MenuItem,
  Checkbox,
  ListItemText,
  InputLabel,
  OutlinedInput,
  Select,
  Grid,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

// redux
import { useSelector, useDispatch } from "react-redux";
import { addChildsOtherDetails } from "../features/child/childSlice";

// mock data
import { hobbies } from "../_mocks_/hobbies";

// ----------------------------------------------------------------------

// constants
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// ----------------------------------------------------------------------

export const AdminAddUserHobbies = () => {
  const navigate = useNavigate();
  const [personHobby, setPersonHobby] = useState([]);

  // read initial data from root state using useSelector hook
  const childInfo = useSelector((state) => state.childInfo.childsInfo);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonHobby(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const RegisterSchema = Yup.object().shape({
    bio: Yup.string().required("Bio required"),
    hobbies: Yup.array().required("Hobbies required"),
  });

  const formik = useFormik({
    initialValues: {
      hobbies: childInfo.childsHobbies,
      bio: childInfo.childsBio,
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      const hobbyAndBiosInfo = {
        childsBio: values.bio,
        childsHobbies: personHobby,
      };

      // add child hobbies and bio details to redux store
      dispatch(addChildsOtherDetails(hobbyAndBiosInfo));
      navigate("/dashboard/adduser/complete");
    },
  });

  /* const handleCreateNewBoy = (childsInfo = childInfo) => {
    const hobbyAndBioInfo = {
      childsBio: formik.values.bio,
      childsHobbies: personHobby,
    };
    // add child hobbies and bio details to redux store
    dispatch(addChildsOtherDetails(hobbyAndBioInfo));

    // add child to child list and clear child object
    dispatch(addChildToListAndResetChildsInfoObject(childsInfo));

    // move back to child URL
    navigate("/register/child");
  }; */

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={3}>
        <Card>
          <CardHeader title="Hobbies" />
          <CardContent>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                  <InputLabel id="multiple-chip-label">
                    Select Hobbies
                  </InputLabel>
                  <Select
                    labelId="multiple-chip-label"
                    id="multiple-chip"
                    multiple
                    value={personHobby}
                    onChange={handleChange}
                    input={<OutlinedInput />}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    <MenuItem disabled value="">
                      <em>Choose your Hobbies</em>
                    </MenuItem>
                    {hobbies.map((hobby) => (
                      <MenuItem key={hobby} value={hobby}>
                        <Checkbox checked={personHobby.indexOf(hobby) > -1} />
                        <ListItemText primary={hobby} />
                      </MenuItem>
                    ))}
                  </Select>

                  <TextField
                    fullWidth
                    label="Bio"
                    {...getFieldProps("bio")}
                    error={Boolean(touched.bio && errors.bio)}
                    helperText={touched.bio && errors.bio}
                  />

                  {/*  <Button
            fullWidth
            size="large"
            variant="contained"
            style={{ textDecoration: "none", color: "inherit" }}
            onClick={() => handleCreateNewBoy()}
          >
            Add another Child
          </Button> */}

                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    Next
                  </LoadingButton>
                </Stack>
              </Form>
            </FormikProvider>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
