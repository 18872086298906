import * as Yup from "yup";
import { useState } from "react";
import { Icon } from "@iconify/react";
import { useFormik, Form, FormikProvider } from "formik";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// material
import {
  Box,
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

// graphql
import { useMutation } from "@apollo/client";

// redux
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { addAccountInfo } from "../../../features/account/accountSlice";

// graphql queries
import { SIGNUP_QUERY, IS_LOGGED_IN } from "../../../gql/UserQueries";
import { cache } from "../../../gql/cache";
import { gqlError } from "../../../gql/gqlError";

// alerts
import { ShowAlert } from "../../Alert";

// ----------------------------------------------------------------------

export const CompleteRegisterForm = () => {
  // password visibility
  const [showPassword, setShowPassword] = useState(false);

  // register mutation
  const [signUp, { error }] = useMutation(SIGNUP_QUERY, {
    errorPolicy: "all",
    onError: gqlError,
  });

  // set role
  const role = "PARENT";

  // read initial data from root state using useSelector hook
  const state = useSelector((state) => state);
  const { account, maleGuardian, femaleGuardian, childInfo } = state;
  const dispatch = useDispatch();

  // validation schema
  const RegisterSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
  });

  const formik = useFormik({
    initialValues: {
      email: account.accountInfo.primaryEmail,
      phoneNumber: account.accountInfo.defaultPhoneNumber,
      password: account.accountInfo.password,
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      // add account info to root state
      const accountData = {
        primaryEmail: values.email,
        primaryNumber: values.phoneNumber,
        password: values.password,
      };
      dispatch(addAccountInfo(accountData));

      // get the single primary or default name
      let thePrimaryName = "";

      // declare father variables
      let fatherAsPrimaryUser = false;

      // declare nother variables
      let motherAsPrimaryUser = false;

      // fathersInfo: comparing the primaryUser to the fathersInfo and setting the primaryUser to true
      if (accountData.primaryEmail === maleGuardian.fathersEmail) {
        thePrimaryName = maleGuardian.fathersName;
        fatherAsPrimaryUser = true;
      }

      // mothersInfo: compare the primaryEmail with the mothersEmail and seting the primaryUser to true
      if (accountData.primaryEmail === femaleGuardian.mothersEmail) {
        thePrimaryName = femaleGuardian.mothersName;
        motherAsPrimaryUser = true;
      }

      // createAccountInput to be pushed to the db
      const createAccountInput = {
        accountInfoInput: {
          name: thePrimaryName,
          email: accountData.primaryEmail,
          password: accountData.password,
          phone: accountData.primaryNumber,
          roles: role,
        },
        parents: [
          {
            name: maleGuardian.fathersName,
            phone: maleGuardian.fathersPhoneNumber,
            email: maleGuardian.fathersEmail,
            roles: role,
            primaryUser: fatherAsPrimaryUser,
          },
          {
            name: femaleGuardian.mothersName,
            phone: femaleGuardian.mothersPhoneNumber,
            email: femaleGuardian.mothersEmail,
            roles: role,
            primaryUser: motherAsPrimaryUser,
          },
        ],
        children: childInfo.children,
      };

      (async () => {
        const { value: accept } = await Swal.fire({
          title: "Terms and conditions",
          input: "checkbox",
          inputValue: 0,
          text: "By clicking 'I agree', you express your consent to the use - whenever necessary - of your son's and your personal data by Hodari Club for the development and administration of its activities, whether the activities are organised by Hodari or by a third party Hodari is directly engaging with.You also agree to Hodari publicly using photographs of her activities in which you or your son may appear, for the purposes of advertising or reporting.At any time, you can exercise your right to the protection of your data by sending mail to P.O. Box 25965 - 0003 Nairobi, Kenya or an email to hodarijuniorclub@gmail.com",
          inputPlaceholder: "I agree",
          allowOutsideClick: false,
          confirmButtonText: 'Continue <i class="fa fa-arrow-right"></i>',
          inputValidator: (result) => !result && "You need to agree with T&C",
        });
        if (accept) {
          // create user add account info to db
          signUp({ variables: { createAccountInput } })
            .then(({ data }) => {
              console.log("Signing Up...");
              if (error) {
                console.log("There has been an error");
                console.log(error);
                ShowAlert("An Error Occured!", "error");
              }
              if (data.createAccount) {
                console.log("logged in ....");
                console.log(`Logging in ${data.createAccount.email}`);

                // assign hodari-session, id and roles values to local storage
                localStorage.setItem("hodari-session", true);
                localStorage.setItem("id", data.createAccount._id);
                localStorage.setItem("role", data.createAccount.roles);

                // setIsLoggedIn(true)
                cache.writeQuery({
                  query: IS_LOGGED_IN,
                  data: {
                    isLoggedIn: localStorage.getItem("hodari-session"),
                  },
                });

                // get next url to redirect to
                const redirectionUrl = (roleAcquired) => {
                  switch (roleAcquired) {
                    case "PARENT":
                      return "/app/profile";

                    case "ADMIN":
                      return "/dashboard/app";

                    default:
                      return "/register";
                  }
                };

                // Swal.fire('Registration Successful :)')
                // direct from /login to dashboard
                window.location = redirectionUrl(data.createAccount.roles);
              }
            })
            .catch((err) => {
              console.log(`ERROR!!!: ${err}`);

              console.log("logging error data");

              ShowAlert("An Error Occured!", "error");
            });
        }
      })();
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {/* radio button for email */}
          {/* The radio button for selecting the default email */}
          <Box sx={{ borderRadius: 16 }}>
            <FormLabel id="email-row-radio-buttons-group-label">
              Select default email
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="email-row-radio-buttons-group-label"
              name="email-row-radio-buttons-group"
              {...getFieldProps("email")}
            >
              <FormControlLabel
                value={maleGuardian.fathersEmail}
                control={<Radio />}
                label={maleGuardian.fathersEmail}
              />
              <FormControlLabel
                value={femaleGuardian.mothersEmail}
                control={<Radio />}
                label={femaleGuardian.mothersEmail}
              />
            </RadioGroup>
          </Box>

          {/* The radio button for selecting the default phone number */}
          <Box sx={{ borderRadius: 16 }}>
            <FormLabel id="phone-number-row-radio-buttons-group-label">
              Select default phone number
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="phone-number-row-radio-buttons-group-label"
              name="phone-number-row-radio-buttons-group"
              {...getFieldProps("phoneNumber")}
            >
              <FormControlLabel
                value={maleGuardian.fathersPhoneNumber}
                control={<Radio />}
                label={maleGuardian.fathersPhoneNumber}
              />
              <FormControlLabel
                value={femaleGuardian.mothersPhoneNumber}
                control={<Radio />}
                label={femaleGuardian.mothersPhoneNumber}
              />
            </RadioGroup>
          </Box>

          {/* Create password */}
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            label="Password"
            {...getFieldProps("password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => setShowPassword((prev) => !prev)}
                  >
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Register
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
};
