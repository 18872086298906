import { createSlice } from "@reduxjs/toolkit";
import { rootState } from "../../app/rootState";

const initialState = rootState;

const allChildrenSlice = createSlice({
  name: "allChildren",
  initialState,
  reducers: {
    addChildToListAndResetChildsInfoObject: (state, action) => {
      const {
        childsName,
        childsDob,
        childsSchool,
        childsBio,
        childsHobbies,
        childsImage,
      } = action.payload;
      state.children.push({
        name: childsName,
        dob: childsDob,
        school: childsSchool,
        bio: childsBio,
        hobbies: childsHobbies,
        imageUrl: childsImage,
        roles: "CHILD",
      });

      // reset childsinfo object
      state.childsInfo = {
        childsFirstName: "",
        childsLastName: "",
        childsName: "",
        childsDob: "2005-01-31",
        childsSchool: "",
        childsBio: "",
        childsHobbies: [],
        childsImage: "",
      };
    },
  },
});

export const { addChildToListAndResetChildsInfoObject } =
  allChildrenSlice.actions;

export default allChildrenSlice.reducer;
