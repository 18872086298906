import { createSlice } from "@reduxjs/toolkit";
import { rootState } from "../../app/rootState";

const initialState = rootState.mothersInfo;

const femaleGuardianSlice = createSlice({
  name: "femaleGuardian",
  initialState,
  reducers: {
    addFemaleGuardian: (state, action) => {
      state.mothersFirstName = action.payload.mothersFirstName;
      state.mothersLastName = action.payload.mothersLastName;
      state.mothersName = action.payload.mothersName;
      state.mothersEmail = action.payload.mothersEmail;
      state.mothersPhoneNumber = action.payload.mothersPhoneNumber;
    },
  },
});

export const { addFemaleGuardian } = femaleGuardianSlice.actions;

export default femaleGuardianSlice.reducer;
