import faker from "faker";
import { sample } from "lodash";
// utils
import { mockImgAvatar } from "../utils/mockImages";

// ----------------------------------------------------------------------

const users = [...Array(24)].map((_, index) => ({
  id: faker.datatype.uuid(),
  avatarUrl: mockImgAvatar(index + 1),
  name: faker.name.findName(),
  email: faker.internet.email(),
  phone: faker.phone.phoneNumber(),
  status: sample(["active", "banned"]),
  role: sample(["PARENT"]),
}));

export default users;
