import { createSlice } from "@reduxjs/toolkit";
import { rootState } from "../../app/rootState";

const initialState = rootState;

const childSlice = createSlice({
  name: "childInfo",
  initialState,
  reducers: {
    addChild: (state, action) => {
      state.childsInfo.childsFirstName = action.payload.childsFirstName;
      state.childsInfo.childsLastName = action.payload.childsLastName;
      state.childsInfo.childsName = action.payload.childsName;
      state.childsInfo.childsDob = action.payload.childsDob;
      state.childsInfo.childsSchool = action.payload.childsSchool;
    },
    addChildsOtherDetails: (state, action) => {
      state.childsInfo.childsBio = action.payload.childsBio;
      state.childsInfo.childsHobbies = action.payload.childsHobbies;
      state.childsInfo.childsImage = action.payload.childsImage;

      // push to the children object
      state.children.push({
        name: state.childsInfo.childsName,
        dob: state.childsInfo.childsDob,
        bio: state.childsInfo.childsBio,
        email: "",
        school: state.childsInfo.childsSchool,
        hobbies: state.childsInfo.childsHobbies,
        imageUrl: state.childsInfo.childsImage,
        roles: "CHILD",
      });

      if (state.children.length === 0) {
        // clear child object
        state.childsInfo.childsName = "";
        state.childsInfo.childsDob = "";
        state.childsInfo.childsSchool = "";
        state.childsInfo.childsBio = "";
        state.childsInfo.childsHobbies = [];
        state.childsInfo.childsReligion = "";
        state.childsInfo.childsImage = "";
      }
    },
  },
});

export const { addChild, addChildsOtherDetails, clearChildObject } =
  childSlice.actions;

export default childSlice.reducer;
