import { createSlice } from "@reduxjs/toolkit";
import { rootState } from "../../app/rootState";

const initialState = rootState.fathersInfo;

const maleGuardianSlice = createSlice({
  name: "maleGuardian",
  initialState,
  reducers: {
    addMaleGuardian: (state, action) => {
      state.fathersFirstName = action.payload.fathersFirstName;
      state.fathersLastName = action.payload.fathersLastName;
      state.fathersName = action.payload.fathersName;
      state.fathersEmail = action.payload.fathersEmail;
      state.fathersPhoneNumber = action.payload.fathersPhoneNumber;
    },
  },
});

export const { addMaleGuardian } = maleGuardianSlice.actions;

export default maleGuardianSlice.reducer;
