// alerts
import Swal from "sweetalert2";

// show alert
export const ShowAlert = (titleText = "Something happened.", alertType) => {
  Swal.fire({
    title: titleText,
    position: "bottom-end",
    timer: 4500,
    timerProgressBar: true,
    toast: true,
    showConfirmButton: false,
    showCancelButton: true,
    cancelButtonText: "Dismiss",
    icon: alertType,
    showClass: {
      popup: "swal2-noanimation",
      backdrop: "swal2-noanimation",
    },
    hideClass: {
      popup: "",
      backdrop: "",
    },
  });
};
