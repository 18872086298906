import * as Yup from "yup";
import { useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

// alerts
import Swal from "sweetalert2";

// redux
import { useSelector } from "react-redux";

// graphql
import { useMutation } from "@apollo/client";
import {
  LOGIN_QUERY,
  IS_LOGGED_IN,
  PASSWORD_RESET_QUERY,
} from "../../../gql/UserQueries";
import { cache } from "../../../gql/cache";
import { gqlError } from "../../../gql/gqlError";

// alerts
import { ShowAlert } from "../../Alert";
// ----------------------------------------------------------------------

export default function LoginForm() {
  // password visibility
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  // query for sign in
  const [signIn] = useMutation(LOGIN_QUERY, {
    errorPolicy: "all",
    onError: gqlError,
  });

  // read data from root state using useSelector hook
  const { email, password, remember } = useSelector((state) => state.signIn);

  const formik = useFormik({
    initialValues: {
      email,
      password,
      remember,
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      const loginInput = {
        email: values.email,
        password: values.password,
      };

      // sign in to the gpl server
      signIn({ variables: { loginInput } })
        .then(({ data }) => {
          console.log("Signing in >>>>");
          console.log(data);

          // check
          if (data.login) {
            console.log(`Logging in ${data.login.email}...`);

            // assign hodari-session and id values to local storage
            localStorage.setItem("hodari-session", true);
            localStorage.setItem("id", data.login._id);
            localStorage.setItem("role", data.login.roles);

            // setIsLoggedIn(true)
            cache.writeQuery({
              query: IS_LOGGED_IN,
              data: {
                isLoggedIn: localStorage.getItem("hodari-session"),
              },
            });

            // get next url to redirect to
            const redirectionUrl = (roleAcquired) => {
              switch (roleAcquired) {
                case "PARENT":
                  return "/app/profile";

                case "ADMIN":
                  return "/dashboard/app";

                default:
                  return "/login";
              }
            };

            // direct from /login to /dashboard or /app
            window.location = redirectionUrl(data.login.roles);
          }
          // handle error
          if (!data.login) {
            console.log("Not login data");
            throw new Error(error);
          }
        })
        .catch((error) => {
          console.log("logging error data");
          console.log(`ERROR!!!: ${error}`);
          const showAlertAndReload = async () => {
            ShowAlert("Incorrect Logins Values", "warning");
          };
          showAlertAndReload().then(() => {
            window.location = "/login";
          });
        });
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  // register mutation
  const [ResetPassword, { error }] = useMutation(PASSWORD_RESET_QUERY, {
    errorPolicy: "all",
    onError: gqlError,
  });

  // reset password
  const handleResetPassword = () => {
    Swal.fire({
      title: "Submit your email to reset password",
      input: "email",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Send Link",
      confirmButtonColor: "#3085d6",
      showLoaderOnConfirm: true,
      preConfirm: async (email) => {
        const passwordResetInput = { email };

        // create a resetter
        try {
          const { data } = await ResetPassword({
            variables: { passwordResetInput },
          });
          console.log(data);
          // when false
          if (!data.passwordReset) {
            throw new Error("Email not found");
          }
          return {
            message: "Kindly Check for a notification in your email :)",
          };
        } catch (error) {
          return Swal.showValidationMessage(`Request failed: ${error}`);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Sent!", result.value.message, "success");
      } else {
        Swal.fire("Cancelled", "Your password was not changed :(", "error");
      }
    });
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps("email")}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            label="Password"
            {...getFieldProps("password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          <FormControlLabel
            control={
              <Checkbox
                {...getFieldProps("remember")}
                checked={values.remember}
              />
            }
            label="Remember me"
          />

          <Link
            variant="subtitle2"
            underline="always"
            onClick={() => handleResetPassword()}
          >
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Login
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
