import { useLocation, Link as RouterLink } from "react-router-dom";
import { useState } from "react";
// material
import { styled } from "@mui/material/styles";
import { Box, Card, Link, Container, Typography } from "@mui/material";
// alerts
import { SweetAlert } from "react-bootstrap-sweetalert";
// layouts
import AuthLayout from "../layouts/AuthLayout";
// components
import Page from "../components/Page";
import { MHidden } from "../components/@material-extend";
import { MaleGuardianRegisterForm } from "../components/authentication/register/MaleGuardianRegisterForm";
import { FemaleGuardianRegisterForm } from "../components/authentication/register/FemaleGuardianRegisterForm";
import { ChildRegisterForm } from "../components/authentication/register/ChildRegisterForm";
import { HobbiesRegisterForm } from "../components/authentication/register/HobbiesRegisterForm";
import { CompleteRegisterForm } from "../components/authentication/register/CompleteRegisterForm";
// change password component
import { ChangePasswordForm } from "../components/authentication/changepassword/ChangePasswordForm";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Register() {
  const location = useLocation();

  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);

  // close alert
  const onClose = () => {
    setShowTermsAndConditions(false);
  };

  // get the pathname
  const guardian1 = location.pathname === "/register";
  const guardian2 = location.pathname === "/register/guardian2";
  const child = location.pathname === "/register/child";
  const hobbies = location.pathname === "/register/hobbies";
  const complete = location.pathname === "/register/complete";
  const changePassword = location.pathname.includes("/change-password/");

  // get year
  const d = new Date();
  const year = d.getFullYear();

  // privacy policy
  const handleTermsAndConditions = () => {
    if (showTermsAndConditions) {
      return (
        <SweetAlert
          title="Terms and Conditions."
          onConfirm={() => onClose()}
          onCancel={() => onClose()}
        >
          I hereby consent that the data I have provided is true to my
          knowledge. I agree to the processing of the personal data that I have
          provided and declare my agreement with the data protection regulations
          in the data privacy policy. I also consent that my child&rsquo;s
          image, name and any relevant data maybe used for campaigns and
          purposes that benefit the organisation. The information may also be
          shared with stakeholders of the organisation.
        </SweetAlert>
      );
    }
    return null;
  };

  return (
    <RootStyle title="Register | Hodari Junior Club">
      <AuthLayout>
        Already have an account? &nbsp;
        <Link
          to="/login"
          underline="none"
          variant="subtitle2"
          component={RouterLink}
        >
          Login
        </Link>
      </AuthLayout>

      <MHidden width="mdDown">
        <SectionStyle>
          <img
            alt="register"
            src="https://edwinnduti.mo.cloudinary.net/Hodari-Junior-Club_logo.png"
          />
        </SectionStyle>
      </MHidden>

      <Container>
        <ContentStyle>
          <Box sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              {guardian1 && "Let's get started."}
              {guardian2 && "Onwards we go!"}
              {child && "Lets Get this done."}
              {hobbies && "Tell us what he loves doing."}
              {complete && "Almost done."}
              {changePassword && "Change Password"}
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              {guardian1 && "Guardian 1 details"}
              {guardian2 && "Guardian 2 details"}
              {child && "Child details"}
              {hobbies && "Make a selection of his Hobbies"}
              {complete && "Complete registration."}
              {changePassword && "Use a secure password"}
            </Typography>
          </Box>

          {/* <RegisterForm /> */}
          {guardian1 && <MaleGuardianRegisterForm />}
          {guardian2 && <FemaleGuardianRegisterForm />}
          {child && <ChildRegisterForm />}
          {hobbies && <HobbiesRegisterForm />}
          {complete && (
            <>
              <CompleteRegisterForm />
              <Typography
                variant="body2"
                align="center"
                sx={{ color: "text.secondary", mt: 3 }}
              >
                By registering, I agree to Hodari&rsquo;s Terms of Service
                <br /> &copy;{year}
                {/* <Link
                  to="#"
                  underline="always"
                  sx={{ color: "#f5891f" }}
                  onClick={() => {
                    setShowTermsAndConditions(true);
                    return handleTermsAndConditions();
                  }}
                >
                  Terms of Service
                </Link>
                &nbsp;and&nbsp;
                <Link
                  to="#"
                  underline="always"
                  sx={{ color: "#f5891f" }}
                  onClick={() => {
                    setShowTermsAndConditions(true);
                    return handleTermsAndConditions();
                  }}
                >
                  Privacy Policy.
                </Link> */}
                {/* &copy;{year} */}
              </Typography>
            </>
          )}
          {changePassword && <ChangePasswordForm />}

          <MHidden width="smUp">
            <Typography variant="subtitle2" sx={{ mt: 3, textAlign: "center" }}>
              Already have an account?&nbsp;
              <Link to="/login" component={RouterLink}>
                Login
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
