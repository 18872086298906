import * as Yup from "yup";
import PropTypes from "prop-types";
import { useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// material
import {
  Box,
  Stack,
  TextField,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Chip,
  MenuItem,
  Checkbox,
  ListItemText,
  InputLabel,
  OutlinedInput,
  Select,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// redux
import { useSelector, useDispatch } from "react-redux";
// gql
import { useMutation } from "@apollo/client";
import { gqlError } from "../../../gql/gqlError";
import {
  UPDATE_CHILD_QUERY,
  ADMIN_UPDATE_CHILD_QUERY,
} from "../../../gql/UserQueries";
// child reducer slice
import { addChild } from "../../../features/child/childSlice";
// mock data
import { hobbies } from "../../../_mocks_/hobbies";

// ----------------------------------------------------------------------

// constants
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// ----------------------------------------------------------------------

EditChild.propTypes = {
  child: PropTypes.object,
};

ChildRegisterForm.propTypes = {
  child: PropTypes.object,
};

ChildHobbies.propTypes = {
  child: PropTypes.object,
};

// child details form
function ChildRegisterForm({ child }) {
  // get child id from url
  const { childId } = useParams();
  const currentRoleOfUser = localStorage.getItem("role");

  // next location
  const hobbiesLocation =
    currentRoleOfUser === "ADMIN"
      ? `/dashboard/children/${child.account._id}/${childId}/edit/hobbies`
      : `/app/children/${childId}/edit/hobbies`;

  // previous location
  const previousLocation =
    currentRoleOfUser === "ADMIN"
      ? `/dashboard/children/${child.account._id}/${childId}`
      : `/app/children/${childId}`;

  // log out if no data
  if (child === null || child.name === null) {
    localStorage.clear();
    window.location.href = "/";
  }

  // set navigator
  const navigate = useNavigate();

  const [goBack, setGoBack] = useState(false);

  // separate name in child
  const nameArray = child.name.split(" ");

  const dispatch = useDispatch();

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("First name required"),
    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Last name required"),
    dob: Yup.string().required("Date of birth required"),
    school: Yup.string().required("School required"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: nameArray[0],
      lastName: nameArray[1],
      dob: child.dob,
      school: child.school,
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      const childsData = {
        childsFirstName: values.firstName,
        childsLastName: values.lastName,
        childsName: values.firstName.concat(" ", values.lastName),
        childsDob: values.dob,
        childsSchool: values.school,
      };

      // add childs details to redux store
      dispatch(addChild(childsData));
      navigate(hobbiesLocation);
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <TextField
              fullWidth
              label="First name"
              {...getFieldProps("firstName")}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />

            <TextField
              fullWidth
              label="Last name"
              {...getFieldProps("lastName")}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>

          <TextField
            fullWidth
            type="date"
            label="Birthday"
            {...getFieldProps("dob")}
            error={Boolean(touched.dob && errors.dob)}
            helperText={touched.dob && errors.dob}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            fullWidth
            type="text"
            label="School"
            {...getFieldProps("school")}
            error={Boolean(touched.school && errors.school)}
            helperText={touched.school && errors.school}
          />

          <Stack direction={{ xs: "column", sm: "row" }} spacing={4}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={() => {
                setGoBack(true);
                navigate(previousLocation);
              }}
              loading={goBack}
            >
              Back
            </LoadingButton>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Next
            </LoadingButton>
          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

// child hobbies form
function ChildHobbies({ child }) {
  // get child id from url
  const { childId } = useParams();
  const navigate = useNavigate();

  // get role of current user
  const currentRoleOfUser = localStorage.getItem("role");

  // next location to move in
  const nextLocation =
    currentRoleOfUser === "ADMIN"
      ? `/dashboard/children/${child.account._id}/${childId}`
      : `/app/children/${childId}`;

  // previous location to  move in
  const previousLocation =
    currentRoleOfUser === "ADMIN"
      ? `/dashboard/children/${child.account._id}/${childId}/edit`
      : `/app/children/${childId}/edit`;

  const [personHobby, setPersonHobby] = useState([]);
  const [goBack, setGoBack] = useState(false);

  // get user id
  const id =
    currentRoleOfUser === "PARENT"
      ? localStorage.getItem("id")
      : child.account._id;

  // read initial data from rootreducer using useSelector hook
  const childInfo = useSelector((state) => state.childInfo.childsInfo);

  // update child mutation
  const [updateChild] = useMutation(UPDATE_CHILD_QUERY, {
    errorPolicy: "all",
    onError: gqlError,
  });

  const [adminUpdateChild] = useMutation(ADMIN_UPDATE_CHILD_QUERY, {
    errorPolicy: "all",
    onError: gqlError,
  });

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonHobby(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const RegisterSchema = Yup.object().shape({
    bio: Yup.string().required("Bio required"),
    hobbies: Yup.array().required("Hobbies required"),
  });

  const formik = useFormik({
    initialValues: {
      hobbies: child.hobbies,
      bio: child.bio,
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      // all child data
      const updateChildInput = {
        userId: id,
        childId,
        name: childInfo.childsName,
        dob: childInfo.childsDob,
        school: childInfo.childsSchool,
        bio: values.bio,
        hobbies: personHobby,
        imageUrl: childInfo.childsImage,
      };

      // update child mutation if parent
      if (currentRoleOfUser === "PARENT") {
        updateChild({ variables: { updateChildInput } })
          .then(({ data }) => {
            if (data) {
              console.log("UpdateChild Query Success");
              window.location.href = nextLocation;
            }
          })
          .catch((error) => {
            console.log("UpdateChild Query Error: ", error);
          });
      } else {
        adminUpdateChild({ variables: { updateChildInput } })
          .then(({ data }) => {
            if (data) {
              console.log("UpdateChild Query by Admin Success");
              window.location.href = nextLocation;
            }
          })
          .catch((error) => {
            console.log("UpdateChild Query by Admin Error: ", error);
          });
      }
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <InputLabel id="multiple-chip-label">Select Hobbies</InputLabel>
          <Select
            labelId="multiple-chip-label"
            id="multiple-chip"
            multiple
            value={personHobby}
            onChange={handleChange}
            input={<OutlinedInput />}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            <MenuItem disabled value="">
              <em>Choose your Hobbies</em>
            </MenuItem>
            {hobbies.map((hobby) => (
              <MenuItem key={hobby} value={hobby}>
                <Checkbox checked={personHobby.indexOf(hobby) > -1} />
                <ListItemText primary={hobby} />
              </MenuItem>
            ))}
          </Select>

          <TextField
            fullWidth
            label="Bio"
            {...getFieldProps("bio")}
            error={Boolean(touched.bio && errors.bio)}
            helperText={touched.bio && errors.bio}
          />

          <Stack direction={{ xs: "column", sm: "row" }} spacing={4}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={() => {
                setGoBack(true);
                navigate(previousLocation);
              }}
              loading={goBack}
            >
              Back
            </LoadingButton>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Save
            </LoadingButton>
          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

export default function EditChild({ child }) {
  // get child id from url
  const { childId } = useParams();
  const location = useLocation();

  // get the pathname
  const updateChild =
    location.pathname === `/app/children/${childId}/edit` ||
    location.pathname ===
      `/dashboard/children/${child.account._id}/${childId}/edit`;
  const updateHobbies =
    location.pathname === `/app/children/${childId}/edit/hobbies` ||
    location.pathname ===
      `/dashboard/children/${child.account._id}/${childId}/edit/hobbies`;
  return (
    <Card>
      <CardHeader title="Change Child Details" />
      <CardContent>
        {updateChild && <ChildRegisterForm child={child} />}
        {updateHobbies && <ChildHobbies child={child} />}
      </CardContent>
    </Card>
  );
}
