import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { useNavigate } from "react-router-dom";
// material
import {
  Stack,
  TextField,
  Grid,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

// redux
import { useSelector, useDispatch } from "react-redux";
import { addFemaleGuardian } from "../features/femaleGuardian/femaleGuardianSlice";

// ----------------------------------------------------------------------

export const AdminAddUser2 = () => {
  const navigate = useNavigate();

  // read initial data from root state using useSelector hook
  const {
    mothersFirstName,
    mothersLastName,
    mothersEmail,
    mothersPhoneNumber,
  } = useSelector((state) => state.femaleGuardian);
  const dispatch = useDispatch();

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("First name required"),
    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Last name required"),
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    phoneNumber: Yup.string()
      .min(5, "Too Short!")
      .required("Phone number is required"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: mothersFirstName,
      lastName: mothersLastName,
      email: mothersEmail,
      phoneNumber: mothersPhoneNumber,
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      const mothersInfo = {
        mothersFirstName: values.firstName,
        mothersLastName: values.lastName,
        mothersName: values.firstName.concat(" ", values.lastName),
        mothersEmail: values.email,
        mothersPhoneNumber: values.phoneNumber,
      };

      // add mother details to redux store
      dispatch(addFemaleGuardian(mothersInfo));
      navigate("/dashboard/adduser/child");
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={3}>
        <Card>
          <CardHeader title="Guardian 2 Details" />
          <CardContent>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    <TextField
                      fullWidth
                      label="First name"
                      {...getFieldProps("firstName")}
                      error={Boolean(touched.firstName && errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                    />

                    <TextField
                      fullWidth
                      label="Last name"
                      {...getFieldProps("lastName")}
                      error={Boolean(touched.lastName && errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                    />
                  </Stack>

                  <TextField
                    fullWidth
                    autoComplete="email"
                    type="email"
                    label="Email address"
                    {...getFieldProps("email")}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />

                  <TextField
                    fullWidth
                    type="text"
                    label="Phone number"
                    {...getFieldProps("phoneNumber")}
                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                    helperText={touched.phoneNumber && errors.phoneNumber}
                  />

                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    Next
                  </LoadingButton>
                </Stack>
              </Form>
            </FormikProvider>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
