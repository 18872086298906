import { createContext, useContext, useState } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import Cookies from "js-cookie";
import jwt from "jsonwebtoken";
// context
import { AuthContext } from "./AuthContext";
// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
import Login from "./pages/Login";
import Register from "./pages/Register";
import DashboardApp from "./pages/DashboardApp";
import Products from "./pages/Products";
import Blog from "./pages/Blog";
import Users from "./pages/Users";
import Children from "./pages/Children";
import AdminViewUser from "./pages/AdminViewUser";
import NotFound from "./pages/Page404";
import UserProfile from "./pages/UserProfile";
import UserChildren from "./pages/UserChildren";
import EditUserProfile from "./pages/EditUserProfile";
import ChangeUserPassword from "./pages/ChangeUserPassword";
import AdminAddUser from "./pages/AdminAddUser";
import AddChild from "./pages/AddChild";
import SingleChild from "./pages/SingleChild";
import Question from "./pages/Question";
import AdminViewChild from "./pages/AdminViewChild";
import AdminEditUser from "./pages/AdminEditUser";
import AdminChangeUserPassword from "./pages/AdminChangeUserPassword";
import { AdminAddUser2 } from "./pages/AdminAddUser2";
import { AdminAddUserChild } from "./pages/AdminAddUserChild";
import { AdminAddUserHobbies } from "./pages/AdminAddUserHobbies";
import { AdminAddUserComplete } from "./pages/AdminAddUserComplete";

// ----------------------------------------------------------------------

const secret = process.env.REACT_APP_SECRET_KEY;

function handleHome(isLoggedIn, role) {
  if (
    (isLoggedIn !== "true" && role !== "PARENT") ||
    (isLoggedIn !== "true" && role !== "ADMIN")
  ) {
    return <LogoOnlyLayout />;
  }
  if (isLoggedIn === "true" && role === "PARENT") {
    return <Navigate to="/app/children" />;
  }
  return <Navigate to="/dashboard/app" />;
}

export default function Router() {
  /*   const [isAuthenicated, setIsAuthenicated] = useState(false);

  try {
    const data = jwt.verify(Cookies.get("jwt"), secret);
    setIsAuthenicated(true);
  } catch (err) {
    setIsAuthenicated(false);
  }
 */
  const isLoggedIn = localStorage.getItem("hodari-session");
  const role = localStorage.getItem("role");

  return useRoutes([
    // accessible to admin
    {
      path: "/dashboard",
      element:
        isLoggedIn === "true" && role === "ADMIN" ? (
          <DashboardLayout />
        ) : (
          <Navigate to="/login" />
        ),
      children: [
        { element: <Navigate to="/dashboard/app" replace /> },
        { path: "app", element: <DashboardApp /> },
        { path: "adduser", element: <AdminAddUser /> },
        { path: "adduser/guardian2", element: <AdminAddUser2 /> },
        { path: "adduser/child", element: <AdminAddUserChild /> },
        { path: "adduser/hobbies", element: <AdminAddUserHobbies /> },
        { path: "adduser/complete", element: <AdminAddUserComplete /> },
        { path: "users", element: <Users /> },
        { path: "users/:id", element: <AdminViewUser /> },
        { path: "users/:id/edit", element: <AdminEditUser /> },
        {
          path: "users/:id/change-password",
          element: <AdminChangeUserPassword />,
        },
        { path: "children", element: <Children /> },
        { path: "children/:id/:childId", element: <AdminViewChild /> },
        { path: "children/:id/:childId/edit", element: <AdminViewChild /> },
        {
          path: "children/:id/:childId/edit/hobbies",
          element: <AdminViewChild />,
        },
        { path: "products", element: <Products /> },
        { path: "blog", element: <Blog /> },
      ],
    },
    // accessible to parent
    {
      path: "/app",
      element:
        isLoggedIn === "true" && role === "PARENT" ? (
          <DashboardLayout />
        ) : (
          <Navigate to="/login" />
        ),
      children: [
        { element: <Navigate to="/app/children" replace /> },
        { path: "children", element: <UserChildren /> },
        { path: "children/:childId", element: <SingleChild /> },
        { path: "children/:childId/edit", element: <SingleChild /> },
        { path: "children/:childId/edit/hobbies", element: <SingleChild /> },
        { path: "profile", element: <UserProfile /> },
        { path: "profile/edit", element: <EditUserProfile /> },
        { path: "profile/change-password", element: <ChangeUserPassword /> },
        { path: "add-child", element: <AddChild /> },
        { path: "add-child/hobbies", element: <AddChild /> },
        { path: "question", element: <Question /> },
      ],
    },
    // accessible to public
    {
      path: "/",
      element: handleHome(isLoggedIn, role),
      children: [
        { element: <Navigate to="/login" replace /> },
        { path: "login", element: <Login /> },
        { path: "register", element: <Register /> },
        { path: "register/guardian2", element: <Register /> },
        { path: "register/child", element: <Register /> },
        { path: "register/hobbies", element: <Register /> },
        { path: "register/complete", element: <Register /> },
        { path: "change-password/:token", element: <Register /> },
        { path: "404", element: <NotFound /> },
        { path: "/", element: <Navigate to="/dashboard" /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
