// material
import { Box, Divider, Typography } from "@mui/material";

// ----------------------------------------------------------------------

export default function Footer() {
  // get year
  const d = new Date();
  const year = d.getFullYear();

  return (
    <>
      <Box
        sx={{
          alignItems: "center",
          justifyContent: "center",
          position: "sticky",
          bottom: 0,
        }}
      >
        <Divider sx={{ my: 1 }} />
        <Typography variant="caption" center>
          &copy; {year} Hodari Junior Club
        </Typography>
      </Box>
    </>
  );
}
