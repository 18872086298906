export const hobbies = [
  "Blogging",
  "Acting",
  "Travelling",
  "Cycling",
  "Martial Arts",
  "Playing Guitar",
  "Playing Piano",
  "Playing Drums",
  "Pool and Billiards",
  "Robotics",
  "Running",
  "Spoken Word",
  "Stamping",
  "Studying",
  "Videography",
  "Vlogging",
  "Working Out",
  "Reading",
  "Watching TV",
  "Wildlife Photography",
  "Playing Video Games",
  "Playing Board Games",
  "Swimming",
  "Cooking",
  "Painting",
  "Singing",
  "Dancing",
  "Drawing",
  "Photography",
  "Writing",
  "Comics",
  "Crafts",
  "Video Games",
  "Board Games",
  "Basketball",
  "Baseball",
  "Hockey",
  "Soccer",
  "Chess",
  "Coding",
];
