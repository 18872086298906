export const rootState = {
  // user id
  id: "",
  // login details
  accountInfo: {
    password: "",
    primaryEmail: "",
    primaryPhoneNumber: "",
    primaryName: "",
  },

  // fathers details at the beginning
  fathersInfo: {
    fathersFirstName: "",
    fathersLastName: "",
    fathersName: "",
    fathersEmail: "",
    fathersPhoneNumber: "",
    primaryUser: false,
  },

  // mothers details at the beginning
  mothersInfo: {
    mothersFirstName: "",
    mothersLastName: "",
    mothersName: "",
    mothersEmail: "",
    mothersPhoneNumber: "",
    primaryUser: false,
  },

  // boy's details
  childsInfo: {
    childsFirstName: "",
    childsLastName: "",
    childsName: "",
    childsDob: "2005-01-31",
    childsSchool: "",
    childsBio: "",
    childsHobbies: [],
    childsImage: "",
  },

  // children's array
  children: [],

  // give permission to terms
  permitInfo: {
    consent: "",
  },
  credentials: {
    email: "",
    password: "",
    remember: true,
  },
};
