import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { useNavigate } from "react-router-dom";
// material
import {
  Stack,
  TextField,
  Grid,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

// redux
import { useSelector, useDispatch } from "react-redux";
import { addMaleGuardian } from "../features/maleGuardian/maleGuardianSlice";

// ----------------------------------------------------------------------

export default function AdminAddUser() {
  // navigator instance
  const navigate = useNavigate();

  // read initial data from root state using useSelector hook
  const {
    fathersFirstName,
    fathersLastName,
    fathersEmail,
    fathersPhoneNumber,
  } = useSelector((state) => state.maleGuardian);
  const dispatch = useDispatch();

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("First name required"),
    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Last name required"),
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    phoneNumber: Yup.string()
      .min(5, "Too Short!")
      .required("Phone number is required,"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: fathersFirstName,
      lastName: fathersLastName,
      email: fathersEmail,
      phoneNumber: fathersPhoneNumber,
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      const fathersInfo = {
        fathersFirstName: values.firstName,
        fathersLastName: values.lastName,
        fathersName: values.firstName.concat(" ", values.lastName),
        fathersEmail: values.email,
        fathersPhoneNumber: values.phoneNumber,
      };

      // add father details to redux store
      dispatch(addMaleGuardian(fathersInfo));
      navigate("/dashboard/adduser/guardian2");
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={3}>
        <Card>
          <CardHeader title="Guardian 1 Details" />
          <CardContent>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    <TextField
                      fullWidth
                      label="First name"
                      {...getFieldProps("firstName")}
                      error={Boolean(touched.firstName && errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                    />

                    <TextField
                      fullWidth
                      label="Last name"
                      {...getFieldProps("lastName")}
                      error={Boolean(touched.lastName && errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                    />
                  </Stack>

                  <TextField
                    fullWidth
                    autoComplete="email"
                    type="email"
                    label="Email address"
                    {...getFieldProps("email")}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />

                  <TextField
                    fullWidth
                    type="text"
                    label="Phone number"
                    {...getFieldProps("phoneNumber")}
                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                    helperText={touched.phoneNumber && errors.phoneNumber}
                  />

                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    Next
                  </LoadingButton>
                </Stack>
              </Form>
            </FormikProvider>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
