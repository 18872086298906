import { useState, useEffect } from "react";
// material
import { Box, Grid, Container, Typography } from "@mui/material";
// gql
import { useQuery } from "@apollo/client";
import { GET_USERS_QUERY } from "../gql/UserQueries";
// components
import Page from "../components/Page";
import {
  AppTasks,
  AppNumberOfAllUsers,
  AppBugReports,
  AppTotalChildren,
  AppNewsUpdate,
  AppWeeklySales,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppCurrentSubject,
  AppConversionRates,
} from "../components/_dashboard/app";

// ----------------------------------------------------------------------

export default function DashboardApp() {
  // users state
  const [users, setUsers] = useState([]);
  const [children, setChildren] = useState([]);
  // get all users
  const { error, loading, data } = useQuery(GET_USERS_QUERY);

  if (error) {
    console.log("Error loading all users: ", error);
  }

  useEffect(() => {
    if (data) {
      // set all users
      setUsers(data.getAllUsers);
      // get all children from each account/user
      const accounts = [];
      data.getAllUsers.map((account) =>
        account.children.map((child) => {
          accounts.push(child);
          return accounts;
        })
      );

      // set total children
      setChildren(accounts);
    }
  }, [data]);

  // set loading
  const isLoading = <div>loading...</div>;
  return (
    <Page title="Dashboard | Hodari Junior Club">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Hi, Welcome back</Typography>
        </Box>
        <Grid container spacing={3}>
          {/* <Grid item xs={12} sm={6} md={3}>
            <AppWeeklySales />
          </Grid> */}
          <Grid item xs={12} sm={6} md={3}>
            <AppNumberOfAllUsers numberOfUsers={users.length} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppTotalChildren numberOfChildren={children.length} />
          </Grid>
          {/* <Grid item xs={12} sm={6} md={3}>
            <AppBugReports />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
}
