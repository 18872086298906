import PropTypes from "prop-types";
// material-ui
import { Grid, Card, Typography, CardHeader, CardContent } from "@mui/material";
// material-lab
import {
  Timeline,
  TimelineItem,
  TimelineContent,
  TimelineSeparator,
  TimelineDot,
} from "@mui/lab";

AccountItem.propTypes = {
  keyName: PropTypes.string,
  parent: PropTypes.object,
};

GuardianInfo.propTypes = {
  parent: PropTypes.object,
  index: PropTypes.number,
};

UserGuardianDetails.propTypes = {
  data: PropTypes.object,
};

// capitalize letters
function titleCase(str) {
  const splitStr = str.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i += 1) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
}

function AccountItem({ keyName, parent }) {
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot
          sx={{
            bgcolor:
              (keyName === "Name" && "primary.main") ||
              (keyName === "Email" && "success.main") ||
              (keyName === "Phone" && "info.main") ||
              (keyName === "Roles" && "error.main"),
          }}
        />
      </TimelineSeparator>
      <TimelineContent>
        <Typography variant="subtitle2">{keyName}</Typography>
        <Typography variant="caption" sx={{ color: "text.secondary" }}>
          {parent[keyName]}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
}

function GuardianInfo({ parent, index }) {
  return (
    <Card
      sx={{
        "& .MuiTimelineItem-missingOppositeContent:before": {
          display: "none",
        },
      }}
    >
      <CardHeader title={`Guardian ${index + 1}`} />
      <CardContent>
        <Timeline>
          {Object.keys(parent).map((key) => (
            <AccountItem key={key} keyName={key} parent={parent} />
          ))}
        </Timeline>
      </CardContent>
    </Card>
  );
}

export default function UserGuardianDetails({ data }) {
  const { parents } = data;
  return (
    <Card>
      <CardHeader title="Guardian(s)" />
      <CardContent>
        <Grid container spacing={2}>
          {parents.map((parent, index) => (
            <Grid
              item
              container
              spacing={5}
              sx={{ margin: "auto" }}
              md={5}
              key={parent.name}
            >
              <GuardianInfo
                key={parent.name}
                parent={{
                  Name: titleCase(parent.name),
                  Phone: parent.phone,
                  Email: parent.email,
                  Roles: parent.roles,
                }}
                index={index}
              />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}
