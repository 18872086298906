import { createSlice } from "@reduxjs/toolkit";
import { rootState } from "../../app/rootState";

const initialState = rootState;

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    addAccountInfo: (state, action) => {
      // initializing the state for the accountInfo object
      state.accountInfo.password = action.payload.password;
      state.accountInfo.primaryEmail = action.payload.primaryEmail;
      state.accountInfo.primaryPhoneNumber = action.payload.primaryNumber;
    },
  },
});

export const { addAccountInfo } = accountSlice.actions;

export default accountSlice.reducer;
