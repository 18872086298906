import { gql } from "@apollo/client";

// create user from role parent
export const SIGNUP_QUERY = gql`
  mutation CreateAccount($createAccountInput: CreateAccountInput!) {
    createAccount(createAccountInput: $createAccountInput) {
      _id
      name
      email
      roles
    }
  }
`;

export const ADMIN_SIGNUP_USER_QUERY = gql`
  mutation AdminCreateAccount($createAccountInput: CreateAccountInput!) {
    adminCreateAccount(createAccountInput: $createAccountInput) {
      _id
      name
      email
      roles
      createdOn
    }
  }
`;

export const LOGIN_QUERY = gql`
  mutation Login($loginInput: LoginInput!) {
    login(loginInput: $loginInput) {
      _id
      email
      roles
    }
  }
`;

export const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`;

export const ADMIN_GET_USER = gql`
  query AdminGetUser($id: ID!) {
    adminGetUser(id: $id) {
      _id
      email
      phone
      roles
      name
      isDeleted
      parents {
        _id
        name
        email
        phone
        roles
        account {
          _id
        }
      }
      children {
        _id
        name
        email
        dob
        school
        imageUrl
        bio
        hobbies
        isDeleted
        account {
          _id
        }
      }
    }
  }
`;

export const GET_USER = gql`
  query GetUser($id: ID!) {
    getUser(id: $id) {
      _id
      email
      phone
      roles
      name
      isDeleted
      parents {
        _id
        name
        email
        phone
        roles
        account {
          _id
        }
      }
      children {
        _id
        name
        email
        dob
        school
        imageUrl
        bio
        hobbies
        religion
        isDeleted
        account {
          _id
        }
      }
    }
  }
`;

// reset password
export const PASSWORD_RESET_QUERY = gql`
  mutation PasswordReset($passwordResetInput: PasswordResetInput) {
    passwordReset(passwordResetInput: $passwordResetInput)
  }
`;

// set new password
export const SET_PASSWORD_QUERY = gql`
  mutation SetNewPassword($newPasswordInput: NewPasswordInput) {
    setNewPassword(newPasswordInput: $newPasswordInput)
  }
`;

// change user password while logged in
export const CHANGE_PASSWORD_QUERY = gql`
  mutation ChangePassword($changePasswordInput: ChangePasswordInput) {
    changePassword(changePasswordInput: $changePasswordInput)
  }
`;

// admin change user password while logged in
export const ADMIN_CHANGE_USER_PASSWORD_QUERY = gql`
  mutation AdminChangeUserPassword($changePasswordInput: ChangePasswordInput) {
    adminChangeUserPassword(changePasswordInput: $changePasswordInput)
  }
`;

// updating user data
export const UPDATE_USER_PROFILE_QUERY = gql`
  mutation UpdateUser($updateUserInput: UpdateUserInput!) {
    updateUser(updateUserInput: $updateUserInput) {
      __typename
      _id
    }
  }
`;

// update child as parent
export const UPDATE_CHILD_QUERY = gql`
  mutation UpdateChild($updateChildInput: UpdateChildInput!) {
    updateChild(updateChildInput: $updateChildInput) {
      __typename
      _id
      children {
        _id
      }
    }
  }
`;

// update child as admin
export const ADMIN_UPDATE_CHILD_QUERY = gql`
  mutation UpdateChildByAdmin($updateChildInput: UpdateChildInput!) {
    updateChildByAdmin(updateChildInput: $updateChildInput) {
      __typename
      _id
      children {
        _id
      }
    }
  }
`;

// update child
export const ADDED_CHILD_QUERY = gql`
  mutation AddChild($addChildInput: AddChildInput!) {
    addChild(addChildInput: $addChildInput) {
      __typename
      _id
      children {
        _id
      }
    }
  }
`;

// ask question
export const ASK_QUESTION_QUERY = gql`
  mutation AskQuestion($askQuestionInput: AskQuestionInput) {
    askQuestion(askQuestionInput: $askQuestionInput)
  }
`;

// get all users
export const GET_USERS_QUERY = gql`
  query {
    getAllUsers {
      __typename
      _id
      email
      phone
      roles
      name
      isDeleted
      children {
        _id
        name
        school
        dob
        roles
        religion
        isDeleted
        account {
          _id
        }
      }
    }
  }
`;

// delete user
export const DELETE_USER_QUERY = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`;

// deactivate user
export const DEACTIVATE_USER_QUERY = gql`
  mutation DeactivateUser($deactivateUserInput: DeactivateUserInput!) {
    deactivateUser(deactivateUserInput: $deactivateUserInput)
  }
`;

// deactivate child
export const DEACTIVATE_CHILD_QUERY = gql`
  mutation DeactivateChild($deactivateChildInput: DeactivateChildInput!) {
    deactivateChild(deactivateChildInput: $deactivateChildInput)
  }
`;

export const ADMIN_UPDATE_USER_PROFILE_QUERY = gql`
  mutation UpdateUserByAdmin($updateUserInput: UpdateUserInput!) {
    updateUserByAdmin(updateUserInput: $updateUserInput) {
      _id
      email
      phone
      roles
      name
      isDeleted
      parents {
        _id
        name
        email
        phone
        roles
        account {
          _id
        }
      }
      children {
        _id
        name
        email
        dob
        school
        imageUrl
        bio
        hobbies
        religion
        isDeleted
        account {
          _id
        }
      }
    }
  }
`;
